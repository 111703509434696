import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import MiddleImg from "../assets/images/middle.png";
import ElemImg from "../assets/images/elem.png";
import PrepImg from "../assets/images/prep.png";
import HighImg from "../assets/images/hidg.png";
import ImgOne from "../assets/images/homes-1.jpeg";
import ImgTwo from "../assets/images/homes-2.jpeg";
import ImgThree from "../assets/images/homes-3.jpeg";
import ImgFour from "../assets/images/homes-4.jpeg";
import BedImg from "../assets/images/bed.png";
import HomeImg from "../assets/images/home.png";



// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';


// import required modules
import { Link, useNavigate } from 'react-router-dom';


const Programs = ({ items, slidesPerPage }) => {
    const navigate = useNavigate();





    const products = [
        {
            title: 'Elementary School Math',
            img: ImgOne,
            desc: 'Our elementary school math program focuses on building a strong foundation in mathematical concepts. We use interactive methods to engage young learners and develop their problem-solving skills'
        },
        {
            title: 'Middle School Math',
            img: ImgTwo,
            desc: 'In our middle school math program, we explore advanced topics and encourage students to think critically. We provide personalized guidance to help students excel in algebra, geometry, and more.'
        },
        {
            title: 'Middle School Math',
            img: ImgThree,
            desc: 'In our middle school math program, we explore advanced topics and encourage students to think critically. We provide personalized guidance to help students excel in algebra, geometry, and more.'
        },
        {
            title: 'Middle School Math',
            img: ImgFour,
            desc: 'In our middle school math program, we explore advanced topics and encourage students to think critically. We provide personalized guidance to help students excel in algebra, geometry, and more.'
        },
        // {
        //     title: 'High School Math',
        //     img: HighImg,
        //     desc: 'Our high school math program covers a wide range of topics, including calculus, statistics, and trigonometry. We prepare students for college-level math and help them achieve academic success.'
        // },
        // {
        //     title: 'Test Prep',
        //     img: PrepImg,
        //     desc: 'Our test prep program equips students with the strategies and knowledge needed to excel in standardized tests such as the SAT and ACT. We provide comprehensive study materials and practice tests.'
        // }
    ]
    return (
        <>
            <div className="mx-auto w-full overflow-hidden mx-auto w-full bg-[#F8F8F8]   pt-0 mb-20  text-[#292D32] h-full" id='gallery'>
                <h2 className="text-2xl md:text-2xl font-semibold text-[#292D32] text-center lg:text-center my-4">
                    Photo Gallery
                </h2>
                <p className="my-6 mt-2  mb-10 text-md lg:w-[100%] text-[#292D32] text-center lg:text-center font-[500]">
                    Get a glimpse  of wonderful life care homes
                </p>

                <Swiper
                    slidesPerView={slidesPerPage}
                    spaceBetween={30}
                    navigation={true}

                    breakpoints={{
                        0: {
                            slidesPerView: 1.2,
                        },
                        640: {
                            slidesPerView: 1,
                        }
                    }}
                    modules={[Navigation]}
                    className="mySwiper"
                >
                    <div className="holder grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
                        {

                            products.length ?

                                products.map((product, index) => <SwiperSlide key={index}>
                                    <div key={index} className="bg-[#F3F3F3]  h-[400px] md:h-[800px] md:max-h-[800px]">

                                        <div>

                                        <img src={product.img} alt={product.img} className='h-[400px] md:h-[800px] md:max-h-[800px] w-full md:object-cover' />
                                        </div>

                                    </div>
                                </SwiperSlide>)

                                : <></>}
                    </div>
                </Swiper>
            </div>
        </>
    );
}


export default Programs